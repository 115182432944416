import Flickity from 'flickity';
import 'flickity-imagesloaded';

const Carousel = {
  elem: document.querySelectorAll('[data-carousel]'),
  defaults: {
    prevNextButtons: false,
    pageDots: true,
    wrapAround: true,
    imagesLoaded: true
  },

  init() {
    this.render();
  },

  flktyInstance(elem, options = {}) {
    const flkty = new Flickity(elem, Object.assign({}, this.defaults, options));
    flkty.select(0);
  },

  render() {
    if (!this.elem.length) return;

    // create flickity instance with options if available
    for (let i = 0; i < this.elem.length; i++) {
      const data = this.elem[i].getAttribute('data-carousel');
      const options = data ? JSON.parse(data) : '';

      if (options && options !== '') {
        Carousel.flktyInstance(this.elem[i], options);
      } else {
        Carousel.flktyInstance(this.elem[i]);
      }
    }
  }
};

export default Carousel;
