import { fetch } from '@mosquitodigitalltd/front-js/utils/fetch';

const Juicer = (function () {
  const containers = [...document.querySelectorAll('[data-juicer]')];
  const filters = [...document.querySelectorAll('[data-juicer-filter]')];
  const endpoint = 'https://www.juicer.io/api/feeds';

  let filter = 'instagram';

  function init() {
    _render();
  }

  async function _getFeed(container, url) {
    try {
      const res = await fetch(url);
      _generateTemplate(container, res);
    } catch (err) {
      throw new Error(err);
    }
  }

  function _generateTemplate(target, res) {
    if (!res.posts.items.length) return;

    target.innerHTML = '';

    const container = document.createElement('div');
    container.classList.add(
      'juicer__feed__container',
      `juicer__feed__container--${filter}`
    );
    const wrapper = document.createElement('div');
    container.appendChild(wrapper);

    res.posts.items.forEach((post) => {
      if (post.source.source === 'Instagram') {
        const template = `
          <div class="juicer__item  juicer__item--instagram">
            <div class="relative">
              <div class="aspect-ratio  aspect-ratio--1x1">
                <a
                  class="juicer__link  aspect-ratio__object  flex"
                  href="${post.full_url}"
                  target="_blank"
                  rel="noopener"
                >
                  <img
                    class="juicer__image  object-fit-cover  w-100  h-100"
                    src="${post.image}"
                    alt=""
                    loading="lazy"
                  >
                </a>
              </div>
              <div class="juicer__icon">
                <svg xmlns="http://www.w3.org/2000/svg" width="31" height="31" viewBox="0 0 31 31">
                  <g fill="#fff" fill-rule="evenodd">
                    <path d="M25.1 7.45a2.21 2.21 0 0 1-2.22-2.2c0-1.21 1-2.2 2.22-2.2 1.23 0 2.23.99 2.23 2.2 0 1.22-1 2.2-2.23 2.2M15.2 24.5a9.48 9.48 0 0 1-9.52-9.43 9.48 9.48 0 0 1 9.52-9.44 9.48 9.48 0 0 1 9.53 9.44 9.48 9.48 0 0 1-9.53 9.43M29.66 4.22a5.75 5.75 0 0 0-1.38-2.11A5.7 5.7 0 0 0 26.14.74C25.5.49 24.51.19 22.7.1 20.74.02 20.16 0 15.2 0c-4.95 0-5.53.02-7.49.1-1.8.09-2.8.39-3.44.64-.87.33-1.49.73-2.14 1.37A5.73 5.73 0 0 0 .75 4.22C.49 4.87.19 5.85.1 7.64 0 9.57 0 10.16 0 15.06s.02 5.49.1 7.43c.1 1.79.4 2.76.65 3.4.33.86.74 1.48 1.38 2.12a5.73 5.73 0 0 0 2.14 1.37c.65.26 1.63.55 3.44.63 1.96.1 2.54.11 7.5.11 4.95 0 5.53-.02 7.49-.1 1.8-.09 2.79-.38 3.44-.64a5.74 5.74 0 0 0 2.14-1.37 5.71 5.71 0 0 0 1.38-2.11c.26-.65.56-1.62.64-3.41.09-1.94.1-2.52.1-7.43 0-4.9-.01-5.49-.1-7.42a9.89 9.89 0 0 0-.64-3.42"/>
                    <path d="M15.2 8.94a6.15 6.15 0 0 0-6.18 6.12 6.15 6.15 0 0 0 6.18 6.12 6.16 6.16 0 0 0 6.19-6.12 6.15 6.15 0 0 0-6.19-6.12"/>
                  </g>
                </svg>
              </div>
            </div>
          </div>
        `;
        wrapper.insertAdjacentHTML('beforeend', template);
      }

      if (post.source.source === 'LinkedIn') {
        const div = document.createElement('div');
        div.innerHTML = post.message;

        const template = '';

        wrapper.insertAdjacentHTML('beforeend', template);
      }

      if (post.source.source === 'YouTube') {
        const date = new Date(post.external_created_at);

        const template = `
          <div class="juicer__item  juicer__item--youtube">
            <div class="relative">
              <div class="aspect-ratio  aspect-ratio--16x9  mb2">
                <a
                  class="juicer__link  aspect-ratio__object  flex"
                  href="${post.full_url}"
                  target="_blank"
                  rel="noopener"
                >
                  <img
                    class="juicer__image  object-fit-cover  w-100  h-100"
                    src="${post.image}"
                    alt=""
                    loading="lazy"
                  >
                </a>
              </div>
              <div class="juicer__icon">
                <svg xmlns="http://www.w3.org/2000/svg" width="35" height="25" viewBox="0 0 35 25">
                  <path fill="#fff" d="M17.93 0c2.07.01 10.81.09 13.2.75a4.44 4.44 0 0 1 3.1 3.16c1.15 4.75.89 12.25.02 17.18a4.44 4.44 0 0 1-3.1 3.15c-2.48.7-11.82.75-13.41.76h-.67c-.99 0-3.44-.03-6.01-.11l-.64-.03c-2.7-.1-5.4-.29-6.56-.62a4.44 4.44 0 0 1-3.1-3.15C-.4 16.36-.08 8.86.74 3.94A4.44 4.44 0 0 1 3.84.78C4.95.46 7.48.28 10.06.17l.65-.03C13.5.04 16.23 0 17.15 0ZM14 7.14v10.72l9.07-5.36L14 7.14Z"/>
                </svg>
              </div>
            </div>
            <p class="inter  f7  fw7  lh-title  grey  pv2  mb0">${date.toLocaleDateString(
              'en-GB',
              { year: 'numeric', month: 'numeric', day: 'numeric' }
            )}</p>
            <h2 class="dm-sans  f1  fw4  lh-title  black  mb0">${
              post.unformatted_message
            }</h2>
          </div>
        `;
        wrapper.insertAdjacentHTML('beforeend', template);
      }

      target.insertAdjacentElement('beforeend', container);
    });
  }

  function _handleFilterClick(event) {
    const linkedinclass = event.currentTarget.getAttribute('class');
    const currentTarget = event.currentTarget;
    const data = JSON.parse(currentTarget.getAttribute('data-juicer-filter'));
    const url = endpoint + '/' + data.url;
    const container = currentTarget
      .closest('section')
      .querySelector('[data-juicer]');

    if (!linkedinclass.includes('linkedincard')) {
      document.querySelector('.juicer__feed').style.display = 'block';
      document.querySelector('.linkedin__feed').style.display = 'none';
    } else {
      document.querySelector('.juicer__feed').style.display = 'none';
      document.querySelector('.linkedin__feed').style.display = 'block';
    }

    filter = data.filter;
    filters.forEach((filter) => filter.classList.remove('is-active'));
    currentTarget.classList.add('is-active');

    container && _getFeed(container, url);
  }

  function _addListeners() {
    filters.length &&
      filters.forEach((filter) =>
        filter.addEventListener('click', _handleFilterClick, false)
      );
  }

  function _render() {
    if (!containers.length) return;

    containers.forEach((container) => {
      const url = container.getAttribute('data-juicer');
      url && _getFeed(container, url);
    });
    _addListeners();
  }

  return {
    init: init
  };
})();

export default Juicer;
