const AnimatedStat = (function () {
  const elem = document.getElementById('js-animated-stat');
  const attr = document.querySelectorAll('[data-animated-stat]');
  const animationDuration = 3000;
  const frameDuration = 1500 / 60;
  const totalFrames = Math.round(animationDuration / frameDuration);
  const easeOutQuad = (t) => t * (2 - t);
  const options = {
    // rootMargin: '20px 0px 20px 0px',
    threshold: window.matchMedia('(min-width: 950px)').matches ? 1 : 0.75
  };

  let observer;

  function init() {
    render();
  }

  function animateCount(stat) {
    let frame = 0;

    const value = parseInt(stat.getAttribute('data-animated-stat'), 10);
    const counter = setInterval(() => {
      frame++;

      const progress = easeOutQuad(frame / totalFrames);
      const currentCount = Math.round(value * progress);

      if (parseInt(stat.innerHTML, 10) !== currentCount) {
        stat.innerHTML = currentCount;
      }

      if (frame === totalFrames) {
        clearInterval(counter);
      }
    }, frameDuration);
  }

  function runCount() {
    return new Promise((resolve) => {
      attr.forEach(animateCount);
      resolve();
    });
  }

  function render() {
    if (!elem) return;

    observer = new IntersectionObserver((entries) => {
      entries.forEach(
        (entry) =>
          entry.isIntersecting &&
          runCount().then(() => {
            observer.unobserve(elem);
          })
      );
    }, options);

    observer.observe(elem);
  }

  return {
    init: init
  };
})();

export default AnimatedStat;
