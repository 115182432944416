/* eslint-disable no-console */

import WebFont from 'webfontloader';

// front-js/components
import LazyLoad from '@mosquitodigitalltd/front-js/components/LazyLoad';

// local components
import AnimatedStat from './components/AnimatedStat';
import Carousel from './components/Carousel';
import Filter from './components/Filter';
import Juicer from './components/Juicer';
import LoadMore from './components/LoadMore';
import Offcanvas from './components/Offcanvas';
import ScrollMonitor from './components/ScrollMonitor';
import StickyHeader from './components/StickyHeader';

class App {
  constructor() {
    this.components = [
      { src: AnimatedStat, options: null },
      { src: Filter, options: null },
      { src: Juicer, options: null },
      { src: LoadMore, options: null },
      { src: Offcanvas, options: null },
      { src: ScrollMonitor, options: null },
      { src: StickyHeader, options: null }
    ];

    // add js class to html
    document.documentElement.className = 'js';

    // webfontloader
    WebFont.load({
      google: {
        families: ['Inter:400,700', 'Roboto:400,700', 'League Gothic']
      },
      active: function () {
        Carousel.init();
      },
      inactive: function () {
        Carousel.init();
      }
    });

    // init array of components
    if (this.components.length) {
      this.components.forEach((component) => {
        typeof component.src === 'object' && component.src.init();

        if (typeof component.src === 'function') {
          new component.src(component.selector, component.options).init();
        }
      });
    }

    this.dynamicImport();
    this.setupLazyLoad();
    this.activeTeamCards();
    this.enableSearch();
  }

  dynamicImport() {
    if (document.querySelector('[data-accordion]')) {
      import(/* webpackChunkName: 'accordion' */ './components/Accordion').then(
        (component) => {
          const accordion = new component.default('[data-accordion]');
          accordion.init();
        }
      );
    }

    if (document.querySelector('.case-study__media__image')) {
      import(
        /* webpackChunkName: 'case-studies' */ './components/CaseStudies'
      ).then((component) => component.default.render());
    }

    if (document.querySelector('[data-video-embed]')) {
      import(
        /* webpackChunkName: 'video-embed' */ '@mosquitodigitalltd/front-js/components/VideoEmbed'
      ).then((component) => {
        const videoEmbed = new component.default('[data-video-embed]', {
          on: {
            render: (event) => console.log(event),
            create: (event) => console.log('VideoEmbed component create', event)
          }
        });
        videoEmbed.init();
      });
    }
  }

  setupLazyLoad() {
    const lazyLoad = new LazyLoad({
      root: document.querySelectorAll('[data-src]'),
      rootMargin: '20px 0px 20px 0px',
      threshold: 0
    });
    lazyLoad.render();
  }

  activeTeamCards() {
    const cards = [...document.querySelectorAll('.card-wrapper--team')];
    const toggle = (card) => {
      cards.forEach((card) => {
        if (card.classList.contains('is-active')) {
          card.classList.remove('is-active');
        }
      });
      card.classList.add('is-active');
    };
    cards &&
      cards.forEach((card) => {
        card.addEventListener('click', () => toggle(card), false);
      });
  }

  enableSearch() {
    const form = document.getElementById('js-search-form');
    const input = document.getElementById('js-search-form-input');
    const buttonList = [...document.querySelectorAll('.js-search-form-toggle')];
    buttonList.forEach((button) => {
      button.addEventListener('click', () => {
        form.classList.toggle('is-active');
        if (form.classList.contains('is-active')) input.focus();
      });
    });
  }
}

window.addEventListener('load', () => new App());
