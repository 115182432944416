const Offcanvas = (function () {
  const menu = document.getElementById('js-offcanvas');
  const open = document.getElementById('js-offcanvas-open');
  const close = document.getElementById('js-offcanvas-close');
  const btn = document.querySelectorAll('.js-offcanvas-btn');
  const target = document.querySelectorAll('[data-offcanvas-target]');
  const back = document.querySelectorAll('.js-offcanvas-back');

  let active = false;

  function _toggle() {
    if (!active) {
      document.body.classList.add('offcanvas-active');
      menu.classList.add('is-active');
      open.style.display = 'none';
      close.style.display = 'block';
    } else {
      document.body.classList.remove('offcanvas-active');
      menu.classList.remove('is-active');
      open.style.display = 'block';
      close.style.display = 'none';
    }
    active = !active;
  }

  function _showChild(event) {
    event.preventDefault();
    const target = event.currentTarget.getAttribute('data-offcanvas-target');
    const child = document.querySelector(`[data-offcanvas-id="${target}"]`);
    child.classList.add('is-active');
  }

  function _hideChild() {
    const active = document.querySelector('.is-active[data-offcanvas-id]');
    active.classList.remove('is-active');
  }

  function _addListeners() {
    for (let i = 0; i < btn.length; i++) {
      btn[i].addEventListener('click', _toggle, false);
    }

    for (let i = 0; i < target.length; i++) {
      target[i].addEventListener('click', _showChild, false);
    }

    for (let i = 0; i < back.length; i++) {
      back[i].addEventListener('click', _hideChild, false);
    }
  }

  function init() {
    if (!menu) return;
    _addListeners();
  }

  return {
    init: init
  };
})();

export default Offcanvas;
