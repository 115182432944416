const ScrollMonitor = (function () {
  const root = [...document.querySelectorAll('[data-scroll-monitor-observe]')];
  const options = {
    // rootMargin: '200px 0px 200px 0px',
    threshold: window.matchMedia('(min-width: 950px)').matches ? 0.9 : 0.75
  };

  let observer = null;

  function init() {
    render();
  }

  function isIntersecting(target) {
    target.classList.add('in-viewport');
    observer.unobserve(target);
  }

  function render() {
    if (!root.length) return;
    if ('IntersectionObserver' in window) {
      observer = new IntersectionObserver((entries) => {
        entries.forEach(
          (entry) => entry.isIntersecting && isIntersecting(entry.target)
        );
      }, options);

      root.forEach((elem) => observer.observe(elem));
    } else {
      Array.from(root).forEach((elem) => elem.classList.add('in-viewport'));
    }
  }

  return {
    init: init
  };
})();

export default ScrollMonitor;
