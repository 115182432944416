const Filter = (function () {
  const elem = document.querySelectorAll('.js-filter');
  const select = document.querySelectorAll('.js-filter-select');
  const cards = [...document.querySelectorAll('[data-card-filter]')];

  function init() {
    _render();
  }

  function _checkSearchParams() {
    const params = new URLSearchParams(window.location.search);
    const filter = params.get('filter');
    filter && _swichCards(filter);
  }

  function _swichCards(value) {
    const params = new URLSearchParams(window.location.search);

    cards.forEach((card) => {
      if (value !== '') {
        const attr = card.getAttribute('data-card-filter');

        if (value === attr) {
          card.style.display = 'block';
          params.set('filter', attr);
          window.history.replaceState({}, '', `${location.pathname}?${params}`);
        } else {
          card.style.display = 'none';
        }
      } else {
        card.style.display = 'block';
        params.delete('filter');
        window.history.replaceState({}, '', location.pathname);
      }
    });
  }

  function _handleChangeEvent() {
    const currentTarget = event.currentTarget;
    const selectedIndex = currentTarget.options[currentTarget.selectedIndex];
    _swichCards(selectedIndex.value);
  }

  function _addListeners() {
    for (let i = 0; i < select.length; i++) {
      select[i].addEventListener('change', _handleChangeEvent, false);
    }
  }

  function _render() {
    if (!elem.length) return;
    _addListeners();
    _checkSearchParams();
  }

  return {
    init: init
  };
})();

export default Filter;
