const StickyHeader = (function () {
  const elem = document.getElementById('js-header');
  const height = elem ? getComputedStyle(elem).height.split('px')[0] : null;
  const offset = height;

  let scrolled = false;
  let timeout;
  let lastScrollTop = 0;

  function init() {
    render();
  }

  function hasWindowScrolled(lastScrollTop, scrollTop) {
    if (!document.body.classList.contains('offcanvas-active')) {
      if (scrollTop > offset && scrollTop > lastScrollTop) {
        if (elem.classList.contains('header--transparent')) {
          elem.style.backgroundColor = '#000';
        }
      }

      if (scrollTop < offset) {
        if (elem.classList.contains('header--transparent'))
          elem.style.backgroundColor = null;
      }
    }

    if (!scrolled) {
      scrolled = true;
    }
  }

  function handleWindowScroll() {
    // if timeout, cancel it
    timeout && window.cancelAnimationFrame(timeout);

    // set new requestAnimationFrame()
    timeout = window.requestAnimationFrame(() => {
      const scrollTop = window.pageYOffset;
      hasWindowScrolled(lastScrollTop, scrollTop);
      lastScrollTop = scrollTop;
    });
  }

  function addListeners() {
    window.addEventListener('scroll', handleWindowScroll, false);
  }

  function render() {
    if (!elem) return;

    // change header if loaded page already scrolled
    elem.classList.contains('header--transparent') && handleWindowScroll();

    // add listeneers
    addListeners();
  }

  return {
    init: init
  };
})();

export default StickyHeader;
