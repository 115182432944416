import Button from '@mosquitodigitalltd/front-js/components/Button';
import { fetch } from '@mosquitodigitalltd/front-js/utils/fetch';

const LoadMore = (function () {
  const elem = document.getElementById('js-posts-block');
  const container = document.getElementById('js-posts-container');
  const footer = document.getElementById('js-posts-footer');

  let btn = document.querySelectorAll('.js-load-more-button');

  function init() {
    _render();
  }

  function _handleClickEvent(event) {
    const currentTarget = event.currentTarget;
    if (currentTarget.type !== 'button') return;

    const url = currentTarget.getAttribute('data-pagination-next');

    Button.setLoading(currentTarget);

    fetch(url, { responseType: 'document' })
      .then((res) => {
        const resContainer = res.getElementById('js-posts-container');
        const resFooter = res.getElementById('js-posts-footer');

        // stop loading
        Button.clearLoading(currentTarget);

        // insert fetched posts into container
        if (resContainer) {
          container.insertAdjacentHTML('beforeend', resContainer.innerHTML);
        }

        if (resFooter) {
          // replace footer
          footer.innerHTML = resFooter.innerHTML;

          // attach click event on inject load more button
          _attachClickEvent();
        } else {
          // remove footer
          footer.parentNode.removeChild(footer);
        }
      })
      .catch((err) => new Error(err));
  }

  function _attachClickEvent() {
    btn = document.querySelectorAll('.js-load-more-button');

    if (btn.length) {
      for (let i = 0; i < btn.length; i++) {
        btn[i].addEventListener('click', _handleClickEvent, false);
      }
    }
  }

  function addListeners() {
    _attachClickEvent();
  }

  function _render() {
    if (!elem) return;
    addListeners();
  }

  return {
    init: init,
    addListeners: addListeners
  };
})();

export default LoadMore;
